const dev = {
  apiUrl: "http://localhost:3333/nimble/v1",
  apiV2Url: "http://localhost:3334/nimble/v2",
  socketUrl: "http://localhost:3335",
  cookieDomain: "localhost",
};

const prod = {
  apiUrl: "https://api.nimbleokr.app/nimble/v1",
  apiV2Url: "https://api.nimbleokr.app/nimble/v2",
  socketUrl: "https://socket.nimbleokr.app",
  cookieDomain: "nimbleokr.app",
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default config;
