import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { animated } from "react-spring";
import { shade } from "polished";

export const Container = styled(animated.aside)`
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;

  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.background.primary};
  
  transition: all 0.2 ease;
  animation: 0.8s ${keyframes`${fadeIn}`};

  div.nav-bar-start div.brand-box {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 1rem;

    img {
      width: 110px;
      height: 110px;

      object-fit: cover;

      /* margin-left: 8px; */
    }
  }

  div.nav-bar-start ul.nav-bar-items {
    list-style: none;
    /* margin-top: 28px; */

    li a.nav-link {
      color: ${({ theme }) => theme.colors.text.secundary};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 20px;
      padding: 12px;
      border-radius: 8px;

      svg {
        margin-right: 18px;
        padding: 6px;
        border-radius: 6px;
        background: ${({ theme }) => shade(0.1, theme.colors.background.primary)};
      }
    }

    li a.nav-link.active-nav-link {
      background: ${({ theme }) => theme.colors.primary};
      color: #fff;

      svg {
        background: ${({ theme }) => shade(0.1, theme.colors.primary)};
        box-shadow: 0 0 4px ${({ theme }) => shade(0.2, theme.colors.primary)};
      }
    }

    li + li {
      margin-top: 12px;
    }
  }

  div.nav-bar-end div.separator {
    width: 100%;
    height: 1px;
    background: #aaa;
    margin: 8px 0 20px 0;
  }

  div.nav-bar-end div.close-side-bar-box,
  div.nav-bar-end div.close-header-box {
    display: flex;
    align-items: center;
    /* padding: 12px; */

    span {
      color: ${({ theme }) => theme.colors.text.secundary};
      font-size: 18px;
      text-decoration: none;
      margin-left: 12px;
    }

    + div {
      margin-top: 12px;
    }
  }

  @media (max-width: 1300px) {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    div.nav-bar-end div.close-side-bar-box,
    div.nav-bar-end div.close-header-box {
      /* display: flex;
      align-items: center;
      padding: 12px; */

      span {
        color: ${({ theme }) => theme.colors.text.secundary};
        font-size: 15px;
        text-decoration: none;
        margin-left: 12px;
      }

      + div {
        margin-top: 12px;
      }
    }
  }
`;
