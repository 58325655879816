import styled from "styled-components";
import { lighten } from "polished";

export const StyledInput = styled.input`
  height: 38px;
  width: 100%;
  outline: 0;
  border: 2px solid ${({ theme }) => lighten(0.3, theme.colors.text.secundary)};
  color: ${({ theme }) => lighten(0.1, theme.colors.text.secundary)};
  background: ${({ theme }) => lighten(0.2, theme.colors.background.primary)};
  border-radius: 4px;
  padding: 0 8px;
  cursor: default;
  transition: all 0.2s ease;

  :hover:not(:focus) {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.background.primary};
  }

  :focus {
    cursor: text;
    border: 3px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background.secundary};
  }
`;
