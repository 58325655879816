import React from "react";
import { CookiesProvider, useCookies } from 'react-cookie';
import {
  AuthProvider,
  ThemeContext,
  ModalProvider,
  HeaderProvider,
  NavbarProvider,
  ScrollableContainerProvider,
} from "./contexts";

import usePersistedState from "./utils/hooks/usePersistedState";

import Routes from './routes';

import { ThemeProvider } from "styled-components";
import light from "./styles/themes/light";
import dark from "./styles/themes/dark";
import GlobalStyle from "./styles/global";

import "./App.css";

const NIMBLE_NEXT_THEME_COOKIE_KEY = '@nimble@okr@theme'

function App() {
  const [cookies] = useCookies()

  const { [NIMBLE_NEXT_THEME_COOKIE_KEY]: nimbleNextThemeState } = cookies

  const [theme, setTheme] = usePersistedState(
    NIMBLE_NEXT_THEME_COOKIE_KEY,
    nimbleNextThemeState || light,
    "colors"
  );

  const toggleTheme = () => {
    setTheme(theme.title === "light" ? dark : light);
  };

  return (
    <CookiesProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ThemeContext.Provider value={{ toggleTheme }}>
            <ModalProvider>
              <HeaderProvider>
                <NavbarProvider>
                  <ScrollableContainerProvider>
                    <GlobalStyle />
                    <Routes />
                  </ScrollableContainerProvider>
                </NavbarProvider>
              </HeaderProvider>
            </ModalProvider>
          </ThemeContext.Provider>
        </ThemeProvider>
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;
