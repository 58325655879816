import styled from "styled-components";
import { animated } from "react-spring";
import { shade } from "polished";

export const Container = styled.li`
  position: relative;

  button svg:last-child {
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  button.dropdown-toggler {
    width: 100%;
    border: 0;
    outline: 0;
    background: transparent;
    color: ${({ theme }) => theme.colors.text.secundary};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    svg:first-child {
      margin-right: 18px;
      padding: 6px;
      border-radius: 6px;
      background: ${({ theme }) => shade(0.1, theme.colors.background.primary)};
    }

    svg:last-child {
      margin: 0 0 0 auto;
      transform: rotate(0deg);
    }
  }

  button.dropdown-toggler.active-nav-link {
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;

    svg:first-child {
      background: ${({ theme }) => shade(0.1, theme.colors.primary)};
      box-shadow: 0 0 4px ${({ theme }) => shade(0.2, theme.colors.primary)};
    }
  }

  button.dropdown-toggler.opened svg:last-child {
    transform: rotate(180deg);
  }
`;

export const DropdownOptions = styled(animated.div)`
  flex-direction: column;
`;
