import axios from "axios";

import config from "../config";

import { getCookie, removeCookie } from "./cookies";

const authErrorCodes = Object.freeze({
  NO_TOKEN_PROVIDED: 1,
  INVALID_TOKEN: 2,
});

const api = axios.create({
  baseURL: config.apiUrl,
});

api.interceptors.request.use(async (config) => {
  const token = getCookie('@nimble@okr@token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data &&
      (error.response.data.errorCode === authErrorCodes.INVALID_TOKEN ||
        error.response.data.errorCode === authErrorCodes.NO_TOKEN_PROVIDED)
    ) {
      // console.log(error.response.data);
      removeCookie('@nimble@okr@token', '/');

      // logout();
      window.location.reload();
      alert("Sessão expirada, faça login novamente");
    }
    return Promise.reject(error);
  }
);

export default api;
