import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { lighten } from "polished";

import "./styles.css";

import { FiLoader } from "react-icons/fi";

function LoadingBackground({ color, size, style }) {
  const { colors } = useContext(ThemeContext);

  return (
    <div className="loading-background-container" style={style}>
      <FiLoader
        className="spin"
        size={size || 35}
        color={color || lighten(0.1, colors.primary)}
      />
    </div>
  );
}

export default LoadingBackground;
