const dark = {
  title: 'dark',

  colors: {
    primary: '#0f414f',
    secundary: '#78f546',
    success: '#3bc14a',
    danger: '#E5383B',
    gray: {
      light: '#E6E8EB',
      medium: '#929493',
      dark: '#444',
    },

    background: {
      primary: '#333',
      secundary: '#232323',
    },

    text: {
      primary: '#FFF',
      secundary: '#F7F8FA',
    },
  }
};

export default dark;
