const light = {
  title: "light",

  colors: {
    primary: '#0f414f',
    secundary: '#78f546',
    success: '#3bc14a',
    danger: '#E5383B',
    gray: {
      light: '#E6E8EB',
      medium: '#929493',
      dark: '#444',
    },

    background: {
      primary: '#E6E8EB',
      secundary: '#F7F8FA',
    },

    text: {
      primary: '#494D4B',
      secundary: '#808080',
    },
  }
};

export default light;
