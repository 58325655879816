import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  input.switch {
    display: none;

    &:after,
    &:before,
    *,
    *:after,
    *:before,
    + label.switch-button {
      box-sizing: border-box;
    }

    &::selection,
    &:after::selection,
    &:before::selection,
    *::selection,
    *:after::selection,
    *:before::selection,
    & + label.switch-button::selection {
      background: none;
    }

    &:disabled + label.switch-button {
      cursor: not-allowed;
    }

    &:not(:disabled):not(:checked) + label.switch-button:hover::after {
      will-change: padding;
      left: 2px;
    }

    &:not(:disabled) + label.switch-button:active {
      box-shadow: inset 0 0 0 2em #e8eae9;

      &::after {
        padding-right: 0.8em;
      }
    }

    &:not(:disabled):checked + label.switch-button:active {
      box-shadow: none;

      &::after {
        margin-left: -0.8em;
      }
    }

    & + label.switch-button {
      outline: 0;
      display: block;
      width: 4em;
      height: 2em;
      position: relative;
      cursor: pointer;
      user-select: none;
      background: ${({ theme }) =>
    theme.title === "dark"
      ? theme.colors.background.secundary
      : shade(0.1, theme.colors.background.primary)};
      border-radius: 2em;
      padding: 2px;
      transition: all 0.4s ease;

      &::after,
      &::before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      &::after {
        left: 0;
        border-radius: 2em;
        background: ${({ theme }) =>
    theme.title === "dark"
      ? theme.colors.background.primary
      : theme.colors.background.secundary};
        transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
          padding 0.3s ease, margin 0.3s ease;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
      }

      &::before {
        display: none;
      }

      /* &:hover::after {
        will-change: padding;
        left: 2px;
      } */

      /* &:active {
        box-shadow: inset 0 0 0 2em #e8eae9;

        &::after {
          padding-right: 0.8em;
        }
      } */
    }

    &:checked + label.switch-button {
      background: ${({ theme }) => theme.colors.primary};

      &:after {
        left: 50%;
        background: ${({ theme }) =>
    theme.title === "dark"
      ? theme.colors.background.primary
      : theme.colors.background.secundary};
      }

      /* &:active {
        box-shadow: none;

        &::after {
          margin-left: -0.8em;
        }
      } */
    }
  }
`;
